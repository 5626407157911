import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { getColorForUser } from "@/utils/helpers";
import { Agency, AgencyGroup, User } from "@socotec.io/socio-vue-components";

const state = () => ({
  currentUser: null,
  userColor: null,
});

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },
  currentUser: (state) => state.currentUser,

  isDev: (state, getter) => getter?.getCurrentUser.isDev(),

  isSupport: (state, getter) => getter?.getCurrentUser.isSupport(),

  checkAppAccess: (state, getter) => (groupsArray) => {
    return getter?.getCurrentUser.isInGroups(groupsArray);
  },
  getUserColor: (state) => state.userColor,
};

const mutations = {
  SET_CURRENT_USER: async (state, currentUser) => {
    state.currentUser = currentUser;
  },
  SET_USER_COLOR: async (state, userColor) => {
    state.userColor = userColor;
  },
};

const actions = {
  async initializeCurrentUser({ dispatch, commit }) {
    // Fetch user, his agency and agency group
    const userResult = await dispatch("fetchCurrentUser");
    const user = userResult.users[0];

    const userColor = getColorForUser(user);
    commit("SET_USER_COLOR", userColor);

    await dispatch("fetchUserAgencyAndAgencyGroup", {
      uuid: user.uuid,
    });
    await dispatch("plugins/fetchPluginByUuid", user.uuid, {
      root: true,
    });
  },
  async fetchUserAgencyAndAgencyGroup(context, { uuid }) {
    const request =
      new socioGrpcClient.user_management.entities.AgencyRetrieveUserAgencyRequest();
    request.setUuid(uuid);
    try {
      const response =
        await socioGrpcClient.user_management.entities.AgencyControllerPromiseClient.retrieveUserAgency(
          request,
          {}
        );
      const agency = response.toObject();

      if (!agency.uuid) {
        return;
      }

      if (agency.agencyGroup) {
        await AgencyGroup.insert({
          data: agency.agencyGroup,
        });
        // Only store agencyGroup relation
        agency.agencyGroup = agency.agencyGroup.uuid;
      }
      return await Agency.insert({
        data: agency,
      });
    } catch (err) {
      console.log(err);
    }
  },
  async fetchCurrentUser({ rootGetters }) {
    const odicUser = rootGetters["oidc/oidcUser"];
    const request =
      new socioGrpcClient.user_management.user.UserRetrieveRequest();
    request.setUuid(odicUser.usermanagementUuid);

    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.retrieve(
        request,
        {}
      );

    const responseAsObj = response.toObject();

    const userFullData = { ...responseAsObj, groups: responseAsObj.groupsList, ...odicUser };
    return await User.insert({
      data: { ...userFullData, isCurrentUser: true },
    });
  },
  async searchUser(context, text) {
    const metadata = {
      filters: JSON.stringify({ search: text }),
    };
    const request = new socioGrpcClient.user_management.user.UserListRequest();
    const response =
      await socioGrpcClient.user_management.user.UserControllerPromiseClient.list(
        request,
        metadata
      );
    const users = response.toObject().resultsList;
    return users
      .filter((user) => user.email.endsWith("socotec.com"))
      .map((user) => new User(user));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
